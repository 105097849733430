import React from 'react'
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import SustainableEnergy from "./services/SustainableEnergy.js"
import EuropeanProjects from "./services/EuropeanProjects.js"
import Digitalization from "./services/Digitalization.js"
import Translation from "./services/Translation.js"
import WebDevelopment from "./services/WebDevelopment.js"
const Container = tw.div`relative`;

function Services() {
  return (
    <div>
        <SustainableEnergy  />
        <EuropeanProjects />
        <Digitalization />
        <Translation />
        <WebDevelopment />
    </div>
  )
}

export default Services