import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeading as HeadingTitle } from "../../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-blue-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-blue-500`;



export default () => {
  const cards = [
    {
      imageSrc:
        "",
      subtitle: "",
      title: "Biz Kimiz",
      description:
        "Elektroteknosoft, sektördeki tecrübeli bir ekiple birlikte, müşterilerimize en iyi hizmeti sunmak için çalışır. Avrupa Birliği projelerinde uzmanlaşmış olan şirketimiz, sürdürülebilir enerji ve çevre dostu çözümler sunarak, dünya genelinde bir fark yaratıyor.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "",
      subtitle: "",
      title: "Misyonumuz",
      description:
        "Elektroteknosoft olarak misyonumuz, enerji ve dijitalleşme alanında sürdürülebilir ve çevre dostu çözümler sunmaktır. Dünyanın daha sürdürülebilir ve dijitalleşmiş bir geleceğe doğru ilerlemesi gerektiğine inanıyoruz ve bu geçişte rol oynamak için taahhütte bulunuyoruz. Uzman ekibimiz, müşterilerimizin ihtiyaçlarını karşılayan özelleştirilmiş çözümler sunmaya adanmıştır ve daha iyi bir dünyaya katkıda bulunmayı hedefler.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "",
      subtitle: "",
      title: "Vizyonumuz",
      description:
        "Elektroteknosoft olarak vizyonumuz, sürdürülebilir ve dijitalleşmiş çözümler alanında lider olmaktır. Enerji ve dijitalleşmenin geleceğinin sürdürülebilirlikte olduğuna inanıyoruz ve daha iyi ve sürdürülebilir bir dünyaya katkıda bulunan yenilikçi çözümler geliştirmeyi taahhüt ediyoruz. Amacımız, müşterilerimizin en yüksek kalite ve müşteri hizmeti standartlarını korurken daha sürdürülebilir ve dijitalleşmiş bir geleceğe geçiş yapmalarına yardımcı olmaktır.",
      url: "https://timerse.com"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        {/* <HeadingInfoContainer>
          <HeadingTitle>Popular Events</HeadingTitle>
          <HeadingDescription>
            Here are some of the most popular events in New York City curated by professionals.
          </HeadingDescription>
        </HeadingInfoContainer> */}

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
  
    </Container>
  );
};
