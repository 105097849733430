import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeading as HeadingTitle } from "../../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-blue-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-blue-500`;



export default () => {
  const cards = [
    {
      imageSrc:
        "",
      subtitle: "",
      title: "Who Are We?",
      description:
        "Elektroteknosoft works with an experienced team to provide our customers with the best possible service. As a company specialized in European Union projects, we are making a difference worldwide by offering sustainable and environmentally-friendly solutions. Elektroteknosoft specializes in providing electricity, sustainable energy, and green energy solutions. We also offer services in web development, digitalisation, and translation. As experts in European Union projects, we work hard to provide our customers with the best possible service.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "",
      subtitle: "Our",
      title: "Mission",
      description:
        "At Elektroteknosoft, our mission is to provide sustainable and eco-friendly solutions in the field of energy and digitalization. We believe that the world needs to move towards a more sustainable and digitalized future, and we are committed to playing our part in this transition. Our team of experts is dedicated to providing customized solutions that meet our clients' needs and contribute to a better world.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "",
      subtitle: "Our",
      title: "Vision",
      description:
        "At Elektroteknosoft, our vision is to be a leader in the field of sustainable and digitalized solutions. We believe that the future of energy and digitalization lies in sustainability, and we are committed to developing innovative solutions that contribute to a better and more sustainable world. Our goal is to help our clients make the transition to a more sustainable and digitalized future, while maintaining the highest standards of quality and customer service.",
      url: "https://timerse.com"
    }
  ];

  return (
    <Container >
      <SingleColumn>
        {/* <HeadingInfoContainer>
          <HeadingTitle>Popular Events</HeadingTitle>
          <HeadingDescription>
            Here are some of the most popular events in New York City curated by professionals.
          </HeadingDescription>
        </HeadingInfoContainer> */}

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
  
    </Container>
  );
};
